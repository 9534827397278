<template>
  <div ref="parameters" class="parameters">
    <!-- 基础参数设置 -->
    <div class="table">
      
      <div class="head">
        <!-- 按钮选项 -->
        <div>
          <el-button v-if="role_basics_parameters_add" @click="add" size="small" type="success">新增</el-button>
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="horseName" label="名称" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="platformId" label="keyType" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.keyType }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="值" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.val }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="role_basics_parameters_edit" type="primary" @click="revise(scope.row)" size="mini"
              >修改</el-button
            >
            <el-button v-if="role_basics_parameters_del" type="danger" size="mini" @click="delData(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 基础参数新增弹出框 -->
    <Addparam
      ref="addParam"
      :getAdminConfigList="getAdminConfigList"
    ></Addparam>
    <!-- 基础参数修改弹出框 -->
    <Reviseparam
      ref="reviseParam"
      :getAdminConfigList="getAdminConfigList"
    ></Reviseparam>
  </div>
</template>

<script>
import Addparam from "./Addparam.vue";
import Reviseparam from "./Reviseparam.vue";
import {
  getAdminConfig_apiak,
  deleteAdminConfig_apiak,
} from "../../../apis/basic";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    Addparam,
    Reviseparam,
  },
  data() {
    return {
      role_basics_parameters_add:getRoleKey('role_basics_parameters_add'),
      role_basics_parameters_edit:getRoleKey('role_basics_parameters_edit'),
      role_basics_parameters_del:getRoleKey('role_basics_parameters_del'),
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [],
    };
  },
  created() {
    this.getAdminConfigList(); // 初始化页面调用获取函数
  },
  methods: {
    // 获取设置列表函数
    async getAdminConfigList() {
      let { code, data } = await getAdminConfig_apiak({
        page: this.currentPage,
        rows: this.count,
      });
      if (code == 0) {
       // console.log(data, "初始化页面获取设置列表");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getAdminConfigList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getAdminConfigList();
    },
    //新增参数
    add() {
      this.$refs.addParam.open();
    },
    //修改参数
    revise(row) {
      this.$refs.reviseParam.open(row);
    },
    // 删除当前行数据
    delData(row) {
      // console.log(row,'当前要删除的当前一行数据');
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delFn(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除当前数据功能函数
    async delFn(row) {
      this.$axios({
        url: "/otaHotel/admin-config/deleteAdminConfig/" + row.id,
      })
        .then((res) => {
        //  console.log(res, "删除成功的响应数据");
          if (res.data.data == "删除成功") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getAdminConfigList(); // 重绘视图
          }
        })
        .catch((error) => {
          console.log(error, "删除失败的响应数据");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.parameters {
  .table {
    width: 100%;
    // height: 550px !important;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>