<template>
  <div class="basics">
    <!-- 设置分类 -->
    <div class="choose">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item index="1" v-if="role_basics_parameters">基础参数设置</el-menu-item>
        <el-menu-item index="2" v-if="role_basics_ordertimeout">订单超时设置</el-menu-item>
        <el-menu-item index="3" v-if="role_basics_hotel">酒店模块设置</el-menu-item>
      </el-menu>
    </div>
    <!-- 对应页面 -->
    <Parameters v-if="index == '1'"></Parameters>
    <Ordertimeout v-if="index == '2'"></Ordertimeout>
    <HotelMaskSetting v-if="index == '3'"></HotelMaskSetting>
  </div>
</template>

<script>
import Ordertimeout from "../../components/dialog/basic/Ordertimeout.vue";
import Parameters from "../../components/dialog/basic/Parameters.vue";
import HotelMaskSetting from '../../components/dialog/basic/HotelMaskSetting.vue'
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { Parameters, Ordertimeout,HotelMaskSetting },
  data() {
    return {
      role_basics_parameters:getRoleKey('role_basics_parameters'),
      role_basics_ordertimeout:getRoleKey('role_basics_ordertimeout'),
      role_basics_hotel:getRoleKey('role_basics_hotel'),
      activeIndex: "1", //管理分类
      index: "1", //1管理分类与组件对应展示的方式
    };
  },
  methods: {
    //设置分类
    handleSelect(key, keyPath) {
    //  console.log(key, keyPath);
      this.index = key;
    },
  },
};
</script>

<style lang="less" scoped>
.basics {
  .choose {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-bottom: 20px;
    .el-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    }
  }
}
</style>