<template>
  <div ref="parameters" class="parameters">
    <!-- 订单超时设置 -->
    <div class="table">
      <div class="head">
        <!-- 按钮选项 -->
        <div>
          <el-button size="small" @click="add" type="success">新 增</el-button>
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="horseName" label="类型" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.typeName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="platformId" label="开始时间" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.startTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.endTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="platformId" label="时长(分钟)" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.timeLength }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="revise(scope.row)" size="mini"
              >修改</el-button
            >
            <el-button type="danger" size="mini" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增订单超时弹出框 -->
    <Addtimeout
      ref="addTimeout"
      :getOutTimeOrderList="getOutTimeOrderList"
    ></Addtimeout>
    <!-- 修改订单超时弹出框 -->
    <Reviseorder
      ref="reviseOrder"
      :getOutTimeOrderList="getOutTimeOrderList"
    ></Reviseorder>
  </div>
</template>

<script>
import Addtimeout from "./Addtimeout.vue";
import Reviseorder from "./Reviseorder.vue";
import {
  getOrderTimeoutConfig_apiak,
  deleteOrderTimeoutConfig_apiak,
} from "../../../apis/basic";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    Addtimeout,
    Reviseorder,
  },
  data() {
    return {
      role_basics_ordertimeout_add:getRoleKey('role_basics_ordertimeout_add'),
      role_basics_ordertimeout_edit:getRoleKey('role_basics_ordertimeout_edit'),
      role_basics_ordertimeout_del:getRoleKey('role_basics_ordertimeout_del'),
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [],
    };
  },
  created() {
    this.getOutTimeOrderList(); // 初始化页面获取订单数据
  },
  methods: {
    // 获取超时订单数据功能函数
    async getOutTimeOrderList() {
      let { code, data } = await getOrderTimeoutConfig_apiak({
        page: this.currentPage,
        rows: this.count,
      });
     // console.log(data, "初始化页面获取超时订单数据");
      this.tableData = data.records;
      this.total = data.total;
    },
    // 点击删除的事件处理函数
    del(row) {
    //  console.log(row, "当前行数据");
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delToolFn(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async delToolFn(row) {
      this.$axios({
        url:
          "/otaHotel/order-timeout-config/deleteOrderTimeoutConfig/" + row.id,
      })
        .then((res) => {
       //   console.log(res, "删除成功的小杨数据");
          if (res.data.data == "删除成功" && res.data.code == 0) {
            this.$message({
              type: "success",
              message: "您已经成功删除该条数据!",
            });
            this.getOutTimeOrderList();  // 视图重绘
          } else {
            this.$message({
              type: "info",
              message: res.data.data,
            });
          }
        })
        .catch((error) => {
          console.log(error, "删除失败的小杨数据");
        });
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getOutTimeOrderList(); // 初始化页面获取订单数据
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getOutTimeOrderList(); // 初始化页面获取订单数据
    },
    //新增订单超时
    add() {
      this.$refs.addTimeout.open();
    },
    //修改订单超时
    revise(row) {
      this.$refs.reviseOrder.open(row);
    },
  },
};
</script>

<style lang="less" scoped>
.parameters {
  .table {
    width: 100%;
    // height: 550px !important;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>