<template>
  <div ref="parameters" class="parameters">
    <!-- 酒店模块设置 -->
    <div class="table">
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%" max-height="400">
        <el-table-column fixed prop="horseName" label="序号" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.serialNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed prop="horseName" label="渠道" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.channel }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="role_basics_hotel_edit" type="primary" @click="revise(scope.row)" size="mini"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <MeiTuan ref="meiTuan"></MeiTuan>
    <YiLong ref="yiLong"></YiLong>
    <FlyPig ref="flyPig"></FlyPig>
    <LeaveWhere ref="leaveWhere"></LeaveWhere>
    <Ctrip ref="ctrip"></Ctrip>
  </div>
</template>

<script>
import MeiTuan from "./MeiTuan.vue";
import YiLong from "./YiLong.vue";
import FlyPig from "./FlyPig.vue";
import LeaveWhere from "./LeaveWhere.vue";
import Ctrip from './Ctrip.vue'
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    MeiTuan,
    YiLong,
    FlyPig,
    LeaveWhere,
    Ctrip,
  },
  data() {
    return {
       role_basics_hotel_edit:getRoleKey('role_basics_hotel_edit'),
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [
        { serialNumber: 1, channel: "携程" },
        { serialNumber: 2, channel: "飞猪" },
        { serialNumber: 3, channel: "艺龙" },
        { serialNumber: 4, channel: "去哪儿" },
        { serialNumber: 5, channel: "美团" },
      ],
    };
  },
  methods: {
    //修改酒店模块设置
    revise(row) {
      let that = this;
      if (row.serialNumber == 5) {
        that.$refs.meiTuan.open(row);
      } else if (row.serialNumber == 3) {
        that.$refs.yiLong.open(row);
      } else if (row.serialNumber == 2) {
        this.$refs.flyPig.open(row);
      } else if (row.serialNumber == 4) {
        this.$refs.leaveWhere.open(row);
      }else if(row.serialNumber==1){
        this.$refs.ctrip.open(row)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.parameters {
  .table {
    width: 100%;
    height: 315px !important;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>