<template>
  <div class="addParam">
    <el-dialog
      title="新增"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="标识" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
            placeholder="请输入标识"
          ></el-input>
        </el-form-item>
        <el-form-item label="值" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.val"
            autocomplete="off"
            placeholder="请输入值"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getAdminConfigList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "", // 名称
        val: "", // 值
      },
      formLabelWidth: "50px",
    };
  },
  methods: {
    open() {
      this.dialogFormVisible = true;
    },
    //确认保存
    onSubmit() {
      this.addSettingConfig();
      if (this.getAdminConfigList()) {
        this.getAdminConfigList();
      }
      this.form = {};
      this.dialogFormVisible = false;
    },
    // 添加设置功能函数
    addSettingConfig() {
      if (this.form.name && this.form.description && this.form.val) {
        this.$axios({
          url: "/otaHotel/admin-config/addAdminConfig",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            name: this.form.name,
            keyType: this.form.description,
            val: this.form.val,
          },
        })
          .then((res) => {
          //  console.log(res, "测试添加设置响应数据");
            if(res.data.code==0&&res.data.data=='添加成功'){
              this.$message({
                type:'success',
                message:'恭喜你已成功添加!'
              })
            }else{
               this.$message({
                type:'info',
                message:res.data.data
              })
            }
          })
          .catch((error) => {
            console.log(error, "测试添加失败响应数据");
          });
      }
    },
    // 关闭弹窗处理函数
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>