<template>
  <div class="reviseOrder">
    <el-dialog
      title="修改"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select
            size="small"
            v-model="form.channel"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" :label-width="formLabelWidth">
          <el-time-select
            placeholder="起始时间"
            v-model="form.startTime"
            :picker-options="{
              start: '00:00',
              step: '00:01',
              end: '24:00',
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="截止时间" :label-width="formLabelWidth">
          <el-time-select
            placeholder="结束时间"
            v-model="form.endTime"
            :picker-options="{
               start: '00:00',
              step: '00:01',
              end: '24:00',
              minTime: form.startTime,
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="时长" :label-width="formLabelWidth">
          <!-- <el-time-select
            v-model="timeLong"
            :picker-options="{
              start: '00:00',
              step: '00:01',
              end: '24:00',
              format:'HH:mm:ss'
            }"
            placeholder="选择时间"
          >
          </el-time-select> -->
           <el-input
            size="small"
            v-model="timeLong"
            autocomplete="off"
            type="number"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxLength='3'
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getTimeLength_apiak } from "../../../apis/basic";
export default {
  props: {
    getOutTimeOrderList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        channel: "", // 类型
        startTime: "",
        endTime: "",
      },
      formLabelWidth: "80px",
      //下拉框s选择
      options: [
        {
          value: 1,
          label: "当日",
        },
        {
          value: 2,
          label: "次日",
        },
      ],
      // 日期 时间
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      today: "", // 当日
      tomorrow: "", // 次日
      timeLong: "", // 时长
      id: "", // 修改数据的Id
    };
  },
  created() {
    var dd = new Date();
    dd.setDate(dd.getDate() + 0); // 获取今天
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1; //获取当前月份的日期
    var d = dd.getDate();
    if (m >= 1 && m <= 9) {
      m = "0" + m;
    }
    if (d >= 0 && d <= 9) {
      d = "0" + d;
    }
    this.today = y + "-" + m + "-" + d;
    var cc = new Date();
    cc.setDate(cc.getDate() + 1); // 获取明天
    var ty = cc.getFullYear();
    var tm = cc.getMonth() + 1; // 获取当前月份
    var td = cc.getDate();
    if (tm >= 1 && tm <= 9) {
      tm = "0" + tm;
    }
    if (td >= 0 && td <= 9) {
      td = "0" + td;
    }
    this.tomorrow = ty + "-" + tm + "-" + td;
  //  console.log(this.today, "哈哈哈哈");
   // console.log(this.tomorrow, "哈哈哈哈");
  },
  methods: {
    // 打开dialog弹窗的数据回显
    open(row) {
    //  console.log(row, "修改超时订单的当前行数据");
      this.form.startTime = row.startTime;
      this.id = row.id; // id 赋值
      this.form.endTime = row.endTime;
      this.dialogFormVisible = true;
      this.form.channel = row.typeName;
      this.timeLong = row.timeLength;
    },
    //确认保存
    onSubmit() {
      this.addTimeOutOrder(); // 调用保存数据函数
      this.form = {};
      this.dialogFormVisible = false;
    },
    addTimeOutOrder() {
      if (this.form.channel && this.form.startTime && this.form.endTime) {
        this.$axios({
          url: "/otaHotel/order-timeout-config/updateOrderTimeoutConfig",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            id: this.id,
            typeName: this.form.channel,
            startTime: this.form.startTime,
            endTime: this.form.endTime,
            timeLength: this.timeLong,
          },
        })
          .then((res) => {
          //  console.log(res, "保存成功的响应数据");
            if (res.data.data == "修改成功" && res.data.code == 0) {
              this.$message({
                message: "恭喜您已成功修改数据!",
                type: "success",
              });
              this.getOutTimeOrderList();
            } else {
              this.$message({
                message: res.data.data,
                type: "info",
              });
            }
          })
          .catch((error) => {
            console.log(error, "保存失败的响应数据");
          });
      }
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
/deep/.el-input__inner {
  width: 260px;
  height: 40px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>