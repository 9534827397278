<template>
  <div class="reviseOrder">
    <el-dialog
      title="编辑去哪儿"
      :visible.sync="dialogFormVisible"
      width="900px"
      top="10rem"
      :close-on-click-modal="false"
    >
      <p class="info">{{ info }}</p>
      <!-- <el-form :model="form">
        <div class="wrap">
          <div class="left">
            <div class="left-item">
              <p class="labelTip">单个房间最大量</p>
              <el-input
                type="number"
                v-model="form.maxRoomQuantity"
                size="mini"
                maxlength="2"
              ></el-input>
            </div>
            <div class="left-item">
              <p class="labelTip">售卖起始时间(小时)</p>
              <el-time-select
                placeholder="生效"
                v-model="form.startTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '24:00',
                }"
              >
              </el-time-select>
            </div>
            <div class="left-item">
              <p>最大连住</p>
              <el-input
                type="number"
                v-model="form.maxlos"
                size="mini"
                maxlength="2"
              ></el-input>
            </div>
            <div class="left-item">
              <p class="labelTip">是否为钟点房</p>
              <el-select v-model="form.isHourlyRoom" placeholder="请选择">
                <el-option
                  v-for="item in isHourRoomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="left-item" v-if="form.isHourlyRoom == true">
              <p>钟点房最晚离店时间</p>
              <el-time-select
                style="margin-left: 10px"
                placeholder="时间"
                v-model="form.latestDepartureTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '24:00',
                }"
              >
              </el-time-select>
            </div>
            <div class="left-item">
              <p>取消规则</p>
              <el-select v-model="form.cancelType" placeholder="请选择">
                <el-option
                  v-for="item in cancelRulesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="right">
            <div class="right-item">
              <p class="labelTip">最小预定间数</p>
              <el-input
                type="number"
                v-model="form.minRoomQuantity"
                size="mini"
                maxlength="2"
              ></el-input>
            </div>
            <div class="right-item">
              <p class="labelTip">售卖结束时间(小时)</p>
              <el-time-select
                placeholder="失效"
                v-model="form.endTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '24:00',
                  minTime: form.endTime,
                }"
              >
              </el-time-select>
              <p style="margin-left: 10px">预定提前小时</p>
              <el-input
                type="number"
                v-model="form.lastReserveTime"
                size="mini"
                maxlength="2"
              ></el-input>
            </div>
            <div class="right-item">
              <p>提前预定天数</p>
              <el-input
                type="number"
                v-model="form.lastReserveDate"
                size="mini"
                maxlength="2"
              ></el-input>
              <p style="margin-left: 10px">连住天数</p>
              <el-input
                type="number"
                v-model="form.los"
                size="mini"
                maxlength="2"
              ></el-input>
            </div>
            <div class="right-item" v-if="form.isHourlyRoom == true">
              <p>钟点房最早入住时间</p>
              <el-time-select
                style="margin-left: 10px"
                placeholder="时间"
                v-model="form.earliestArriveTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '24:00',
                }"
              >
              </el-time-select>
            </div>
            <div class="right-item" v-if="form.isHourlyRoom == true">
              <p>入住小时数</p>
              <el-input
                type="number"
                v-model="form.stayHours"
                size="mini"
                maxlength="2"
              ></el-input>
            </div>
            <div class="right-item" v-if="form.cancelType == '1'">
              <p class="labelTip">最晚取消时间</p>
               <el-select
                class="time"
                v-model="form.cancelDay"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in lastCancelRulesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-time-select
                style="margin-left: 10px"
                placeholder="时间"
                v-model="form.lastCancelTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:60',
                  end: '24:00',
                }"
              >
              </el-time-select>
            </div>
            <div class="right-item" v-if="form.cancelType == '1'">
              <p>逾期扣款</p>
              <el-radio v-model="form.penaltyBasicStandard" label="0"
                >扣全款</el-radio
              >
              <el-radio v-model="form.penaltyBasicStandard" label="1"
                >扣首日</el-radio
              >
            </div>
          </div>
        </div>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form> -->







      
      <el-form :model="qunaSellData">
        <el-row style="margin-top: 10px">
          <el-col class="leftlabelbox" :span="6"> 单个房间最大房量 </el-col>
          <el-col :span="3">
            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.maxRoomQuantity"
              placeholder="1"
            ></el-input>
          </el-col>
          <el-col :span="13">
            <span class="leftlabelbox"> 预定最小房间</span>

            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.minRoomQuantity"
              placeholder="1"
            ></el-input>
            <!-- <span class="marginlar">最大可入住人</span>
        <el-input
          class="width88"
          size="mini"
          v-model="qunaSellData."
          placeholder="1"
        ></el-input> -->
          </el-col>
        </el-row>
        <el-row class="margintop20">
          <el-col class="leftlabelbox" :span="6"> 售卖起始小时 </el-col>
          <el-col :span="3">
            <!-- <el-input
          class="width88"
          size="mini"
          v-model="qunaSellData.startTime"
          placeholder="1"
        ></el-input> -->
            <el-select
              size="mini"
              class="width88"
              placeholder="10:00"
              v-model="qunaSellData.startTime"
            >
              <el-option
                v-for="item in timers"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="13">
            <span class="leftlabelbox"> 售卖结束小时</span>

            <!-- <el-input
          class="width88"
          size="mini"
          v-model="qunaSellData.endTime"
          placeholder="1"
        ></el-input> -->
            <el-select
              size="mini"
              class="width88"
              placeholder="10:00"
              v-model="qunaSellData.endTime"
            >
              <el-option
                v-for="item in timers"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>

            <div
              class="leftlabelbox"
              style="display: inline-block; margin-left: 20px"
            >
              预定提前小时
            </div>
            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.minAdvHours"
              placeholder="1"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="margintop20">
          <el-col class="leftlabelbox" :span="6"> 最大连住 </el-col>
          <el-col :span="3">
            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.maxlos"
              placeholder="1"
            ></el-input>
          </el-col>
          <el-col :span="13">
            <span class="leftlabelbox">最小连住</span>
            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.los"
              placeholder="1"
            ></el-input>
            <div
              class="leftlabelbox"
              style="display: inline-block; margin-left: 20px"
            >
              预定提前天数
            </div>

            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.minDays"
              placeholder="1"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="margintop20">
          <el-col class="leftlabelbox" :span="6"> 是否为钟点房 </el-col>
          <el-col :span="3">
            <el-select
              size="mini"
              class="width88"
              v-model="qunaSellData.isHourlyRoom"
            >
              <!-- <el-option label="是" value="true"></el-option>
              <el-option label="否" value="false"></el-option> -->
               <el-option
                  v-for="item in isHourRoomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>

            </el-select>
          </el-col>
          <el-col :span="13" v-show="qunaSellData.isHourlyRoom == 'true'">
            <span class="leftlabelbox">钟点房最早入住时间</span>

            <el-select
              size="mini"
              filterable
              allow-create
              class="width88"
              placeholder="14:00"
              v-model="qunaSellData.earliestArriveTime"
            >
              <el-option
                v-for="item in timers"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row
          class="margintop20"
          v-show="qunaSellData.isHourlyRoom == 'true'"
        >
          <el-col class="leftlabelbox" :span="6"> 钟点房最晚离店时间 </el-col>
          <el-col :span="3">
            <!-- <el-input
          class="width88"
          size="mini"
          v-model="qunaSellData.latestDepartureTime"
          placeholder="12：00"
        ></el-input> -->

            <el-select
              size="mini"
              filterable
              allow-create
              class="width88"
              placeholder="18:00"
              v-model="qunaSellData.latestDepartureTime"
            >
              <el-option
                v-for="item in timers"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="13">
            <span class="leftlabelbox">入住小时数</span>
            <el-input
              class="width88"
              size="mini"
              v-model="qunaSellData.stayHours"
              placeholder="2小时"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="margintop20">
          <el-col class="leftlabelbox" :span="6"> 取消规则 </el-col>
          <el-col :span="3">
            <el-select
              size="mini"
              class="width88"
              v-model="qunaSellData.cancelType"
            >
              <!-- <el-option label="免费取消" value="0"></el-option>
              <el-option label="限时取消" value="1"></el-option>
              <el-option label="不可取消" value="2"></el-option> -->
               <el-option
                  v-for="item in cancelRulesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
            </el-select>
          </el-col>
          <el-col :span="13" v-if="qunaSellData.cancelType == 1">
            <span class="leftlabelbox">最晚取消时间</span>
            <el-select
              size="mini"
              class="marginright10"
              style="width: 120px"
              v-model="qunaSellData.cancelDay"
            >
              <el-option
                v-for="item in cancelDayList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              size="mini"
              class="marginright10"
              style="width: 80px"
              v-model="qunaSellData.lastCancelTime"
            >
              <el-option
                v-for="item in timers"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
            <span style="margin-left: 20px" class="leftlabelbox">不扣款</span>
          </el-col>
        </el-row>
        <el-row class="margintop20" v-if="qunaSellData.cancelType == 1">
          <el-col class="leftlabelbox" :span="6"> 逾期扣款 </el-col>
          <el-col :span="17" class="radiobox">
            <el-radio-group
              class="marginright10"
              size="mini"
              v-model="qunaSellData.penaltyBasicStandard"
            >
              <el-radio :label="0">扣全款</el-radio>
              <el-radio :label="1">扣首日</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <div class="diafooterbtn">
          <el-button
            @click="close"
            style="width: 84px; margin-right: 10%"
            size="small"
            type="primary"
            plain
            >取消</el-button
          >
          <el-button
            style="width: 84px"
            @click="onSubmit"
            size="small"
            type="primary"
            >保存</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getHotelTemplateSettingList_apiak } from "../../../apis/basic";
export default {
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "80px",
      form: {
        maxRoomQuantity: "", // 单个房间最大房量
        minRoomQuantity: "", // 最小预定间数
        lastReserveTime: "", // 提前预定小时
        startTime: "", // 售卖起始时间
        lastReserveDate: "", // 提前预定天数
        endTime: "", // 售卖结束小时
        maxlos: "", // 最大连住
        los: "", // 连住天数 (最下连住)
        isHourlyRoom: "", // 是否为钟点房
        latestDepartureTime: "", // 钟点房最晚离店时间
        earliestArriveTime: "", // 钟点房最早到店时间
        stayHours: "", // 入住小时数
        cancelType: "", // 取消规则
        cancelDay: "", // 最晚取消时间(天)
        lastCancelTime: "", // 最晚取消时间(08:00)
        penaltyBasicStandard: "", // 选择扣款方式单选
      },
      info: "请修改想要修改的数据，并进行设置，最终时间以最后一次设置为准",
      isHourRoomOptions: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      cancelRulesOptions: [
        {
          value: 0,
          label: "免费取消",
        },
        {
          value: 1,
          label: "限时取消",
        },
        {
          value: 2,
          label: "不可取消",
        },
      ],
      lastCancelRulesOptions: [
        { value: 0, label: "入住前0天" },
        { value: 1, label: "入住前1天" },
        { value: 2, label: "入住前2天" },
        { value: 3, label: "入住前3天" },
        { value: 4, label: "入住前4天" },
        { value: 5, label: "入住前5天" },
        { value: 6, label: "入住前6天" },
        { value: 7, label: "入住前7天" },
        { value: 8, label: "入住前8天" },
        { value: 9, label: "入住前9天" },
        { value: 10, label: "入住前10天" },
        { value: 11, label: "入住前11天" },
        { value: 12, label: "入住前12天" },
        { value: 13, label: "入住前13天" },
        { value: 14, label: "入住前14天" },
        { value: 15, label: "入住前15天" },
        { value: 16, label: "入住前16天" },
        { value: 17, label: "入住前17天" },
        { value: 18, label: "入住前18天" },
        { value: 19, label: "入住前19天" },
        { value: 20, label: "入住前20天" },
        { value: 21, label: "入住前21天" },
        { value: 22, label: "入住前22天" },
        { value: 23, label: "入住前23天" },
        { value: 24, label: "入住前24天" },
        { value: 25, label: "入住前25天" },
        { value: 26, label: "入住前26天" },
        { value: 27, label: "入住前27天" },
        { value: 28, label: "入住前28天" },
        { value: 29, label: "入住前29天" },
        { value: 30, label: "入住前30天" },
        { value: 31, label: "入住前31天" },
        { value: 32, label: "入住前32天" },
        { value: 33, label: "入住前33天" },
        { value: 34, label: "入住前34天" },
        { value: 35, label: "入住前35天" },
        { value: 36, label: "入住前36天" },
        { value: 37, label: "入住前37天" },
        { value: 38, label: "入住前38天" },
        { value: 39, label: "入住前39天" },
        { value: 40, label: "入住前40天" },
        { value: 41, label: "入住前41天" },
        { value: 42, label: "入住前42天" },
        { value: 43, label: "入住前43天" },
        { value: 44, label: "入住前44天" },
        { value: 45, label: "入住前45天" },
        { value: 46, label: "入住前46天" },
        { value: 47, label: "入住前47天" },
        { value: 48, label: "入住前48天" },
        { value: 49, label: "入住前49天" },
        { value: 50, label: "入住前50天" },
        { value: 51, label: "入住前51天" },
        { value: 52, label: "入住前52天" },
        { value: 53, label: "入住前53天" },
        { value: 54, label: "入住前54天" },
        { value: 55, label: "入住前55天" },
        { value: 56, label: "入住前56天" },
        { value: 57, label: "入住前57天" },
        { value: 58, label: "入住前58天" },
        { value: 59, label: "入住前59天" },
        { value: 60, label: "入住前60天" },
        { value: 61, label: "入住前61天" },
        { value: 62, label: "入住前62天" },
        { value: 63, label: "入住前63天" },
        { value: 64, label: "入住前64天" },
        { value: 65, label: "入住前65天" },
        { value: 66, label: "入住前66天" },
        { value: 67, label: "入住前67天" },
        { value: 68, label: "入住前68天" },
        { value: 69, label: "入住前69天" },
        { value: 70, label: "入住前70天" },
        { value: 71, label: "入住前71天" },
        { value: 72, label: "入住前72天" },
        { value: 73, label: "入住前73天" },
        { value: 74, label: "入住前74天" },
        { value: 75, label: "入住前75天" },
        { value: 76, label: "入住前76天" },
        { value: 77, label: "入住前77天" },
        { value: 78, label: "入住前78天" },
        { value: 79, label: "入住前79天" },
        { value: 80, label: "入住前80天" },
        { value: 81, label: "入住前81天" },
        { value: 82, label: "入住前82天" },
        { value: 83, label: "入住前83天" },
        { value: 84, label: "入住前84天" },
        { value: 85, label: "入住前85天" },
        { value: 86, label: "入住前86天" },
        { value: 87, label: "入住前87天" },
        { value: 88, label: "入住前88天" },
        { value: 89, label: "入住前89天" },
        { value: 90, label: "入住前90天" },
      ], // 限时取消
      qunaSellData: {
        cancelDay: "",
        cancelType: "",
        checkInFrom: "",
        checkInTo: "",
        checkOutTo: "",
        earliestArriveTime: "",
        lastCancelTime: "",
        lastReserveDate: "",
        lastReserveTime: "",
        latestArrivalTime: "",
        los: "",
        maxlos: "",
        maxDays: "",
        maxRoomQuantity: "",
        minDays: "",
        minRoomQuantity: "",
        penaltyBasicStandard: "",
        percentage: "",
        platform: "4",
        stayHours: "",
        isHourlyRoom: "否",
        startTime: "",
        endTime: "",
        latestDepartureTime: "",
        minAdvHours: "",
        maxAdvHours: "",
      },
      timers: [
        { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "24:00", id: 25 },
      ],
      cancelDayList: [],
    };
  },
  created() {
    this.cancelDayList = [];
    for (let i = 0; i < 90; i++) {
      let data = { label: `入住前${i}天`, id: i };
      this.cancelDayList.push(data);
    }
  },
  methods: {
    // 打开dialog弹窗的数据回显
    open() {
      this.dialogFormVisible = true;
      this.getHotelTemplateListFn(); // 打开弹窗的时候调用函数获取酒店模板列表设置
    },
    // 获取模板设置功能函数
    async getHotelTemplateListFn() {
      let { data } = await getHotelTemplateSettingList_apiak({
        page: 1,
        rows: 999,
      });
      data.records.forEach((item) => {
        if (item.platform == "4") {
          this.qunaSellData = item;
          this.qunaSellData.isHourlyRoom = item.isHourlyRoom;
        }
      });
      //  console.log(this.qunaSellData, "你是去哪儿的表单数据吗？是的我是");
    },
    // 编辑保存设置功能函数
    saveSettingFn() {
      if (this.qunaSellData) {
        this.$axios({
          url: "/otaHotel/roomTemplate/creat",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            los: this.qunaSellData.los, // 最小连住
            maxlos: this.qunaSellData.maxlos, // 最大连住
            minRoomQuantity: this.qunaSellData.minRoomQuantity, // 单个房间最大量
            maxRoomQuantity: this.qunaSellData.maxRoomQuantity, // 最大预定间数
            startTime: this.qunaSellData.startTime, // 售卖开始时间
            endTime: this.qunaSellData.endTime, // 售卖结束时间
            lastReserveTime: this.qunaSellData.lastReserveTime, // 提前预定小时(后续可能更改)
            maxlos: this.foqunaSellDatarm.maxlos, // 最大连住
            lastReserveDate: this.qunaSellData.lastReserveDate, // 提前预定天数
            los: this.qunaSellData.los, // 连住天数
            isHourlyRoom: this.qunaSellData.isHourlyRoom, // 是否为钟点房
            earliestArriveTime:
              this.qunaSellData.isHourlyRoom == true
                ? this.qunaSellData.earliestArriveTime
                : "", // 钟点房最早到店时间
            latestDepartureTime:
              this.qunaSellData.isHourlyRoom == true
                ? this.qunaSellData.latestDepartureTime
                : "", // 钟点房最晚离店时间
            stayHours:
              this.qunaSellData.isHourlyRoom == true
                ? this.qunaSellData.stayHours
                : "", // 入住小时数
            penaltyBasicStandard:
              this.qunaSellData.cancelType == "0"
                ? ""
                : this.qunaSellData.cancelType == "1"
                ? this.qunaSellData.penaltyBasicStandard
                : this.qunaSellData.cancelType == "2"
                ? ""
                : "", // 扣款类型
            cancelType: this.qunaSellData.cancelType, // 取消规则(0为免费取消，1为限时取消，2为不可取消)
            cancelDay:
              this.qunaSellData.cancelType == "0"
                ? ""
                : this.qunaSellData.cancelType == "1"
                ? this.qunaSellData.cancelDay
                : this.qunaSellData.cancelType == "2"
                ? ""
                : "", // 最晚提前几天取消
            lastCancelTime:
              this.qunaSellData.cancelType == "0"
                ? ""
                : this.qunaSellData.cancelType == "1"
                ? this.qunaSellData.lastCancelTime
                : this.qunaSellData.cancelType == "2"
                ? ""
                : "", // 最晚取消时间
            platform: this.qunaSellData.platform, // 去哪儿
            id: this.qunaSellData.id, // id
          },
        })
          .then((res) => {
            //  console.log(res, "保存成功的响应数据");
            if (res.data.code == 0 || res.data.msg == "调用成功") {
              this.$message({
                type: "success",
                message: "恭喜您成功更改去哪儿模板设置",
              });
            } else {
              this.$message({
                type: "info",
                message: res.data.data,
              });
            }
          })
          .catch((error) => {
            console.log(error, "保存失败的响应数据");
          });
      }
    },
    //确认保存
    onSubmit() {
      this.dialogFormVisible = false;
      this.saveSettingFn(); // 调用保存修改函数
      this.qunaSellData = {};
    },
    // 关闭
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
/deep/.el-input__inner {
  width: 260px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
/deep/.el-dialog__body {
  padding: 7px 10px 20px 20px !important;
  box-sizing: border-box;
}
/deep/.percentageInput {
  width: 60px !important;
  input {
    width: 60px !important;
  }
}
.el-form {
  width: 100%;
  margin: auto;
  .el-form-item {
    margin-bottom: 0px !important;
  }
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input {
  width: 100px;
  height: 28px;
  border-radius: 4px;
  font-size: 12px;
}
/deep/.el-input__inner {
  width: 100px;
  height: 28px;
  background-color: #f1f1f1;
}
/deep/.el-form-item__label {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
/deep/.el-input__icon {
  line-height: 25px;
}
.time {
  width: 120px;
  /deep/.el-input--suffix {
    width: 120px !important;
    .el-input__inner {
      width: 120px !important;
    }
  }
}
.info {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}
.wrap {
  width: 100%;
  display: flex;
  .left {
    width: 32%;
    .left-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 20px 0px;
      p {
        font-size: 12px;
        padding: 0px 2px 0px 0px;
        box-sizing: border-box;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-right: 10px;
      }
    }
  }
  .right {
    width: 78%;
    padding: 0px 0px 0px 30px;
    box-sizing: border-box;
    .right-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 20px 0px;
      p {
        font-size: 12px;
        padding: 0px 2px 0px 0px;
        box-sizing: border-box;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-right: 10px;
      }
    }
  }
}
.fontRule {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.leftlabelbox {
  text-align: right;
  padding-right: 20px;
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.fontsize12 {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.radiobox {
  line-height: 28px;
  height: 28px;
}
.titlebox {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  margin-left: 50px;
}
.width88 {
  width: 95px;
}
.marginlar {
  margin-left: 10px;
  // margin-right: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.marginleft10 {
  margin-left: 10px;
}
.marginright10 {
  margin-right: 10px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>