var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviseOrder"},[_c('el-dialog',{attrs:{"title":"修改","visible":_vm.dialogFormVisible,"width":"35%","top":"15rem","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"类型","label-width":_vm.formLabelWidth}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择类型"},model:{value:(_vm.form.channel),callback:function ($$v) {_vm.$set(_vm.form, "channel", $$v)},expression:"form.channel"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.label}})}),1)],1),_c('el-form-item',{attrs:{"label":"开始时间","label-width":_vm.formLabelWidth}},[_c('el-time-select',{attrs:{"placeholder":"起始时间","picker-options":{
            start: '00:00',
            step: '00:01',
            end: '24:00',
          }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('el-form-item',{attrs:{"label":"截止时间","label-width":_vm.formLabelWidth}},[_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
             start: '00:00',
            step: '00:01',
            end: '24:00',
            minTime: _vm.form.startTime,
          }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1),_c('el-form-item',{attrs:{"label":"时长","label-width":_vm.formLabelWidth}},[_c('el-input',{attrs:{"size":"small","autocomplete":"off","type":"number","oninput":"value=value.replace(/[^\\d]/g,'')","maxLength":"3"},model:{value:(_vm.timeLong),callback:function ($$v) {_vm.timeLong=$$v},expression:"timeLong"}})],1),_c('el-form-item',[_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"size":"small","type":"primary","plain":""},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("保存")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }