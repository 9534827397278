<template>
  <div class="addTimeout">
    <el-dialog
      title="新增"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select
            size="small"
            v-model="form.channel"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" :label-width="formLabelWidth">
          <el-time-select
            placeholder="起始时间"
            v-model="form.startTime"
            :picker-options="{
              start: '00:00',
              step: '00:01',
              end: '24:00',
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="截止时间" :label-width="formLabelWidth">
          <el-time-select
            placeholder="结束时间"
            v-model="form.endTime"
            :picker-options="{
              start: '00:00',
              step: '00:01',
              end: '24:00',
              minTime: form.startTime,
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="时长" :label-width="formLabelWidth">
           <el-input
            size="small"
            v-model="timeLong"
            autocomplete="off"
            type="number"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxLength='3'
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getTimeLength_apiak } from "../../../apis/basic";
export default {
  props: {
    getOutTimeOrderList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        channel: "", // 类型
        startTime: "",
        endTime: "",
      },

      formLabelWidth: "80px",
      //下拉框s选择
      options: [
        {
          value: 1,
          label: "当日",
        },
        {
          value: 2,
          label: "次日",
        },
      ],
      // 日期 时间
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      today: "", // 当日
      tomorrow: "", // 次日
      timeLong: "", // 时长
    };
  },
  created() {
    var day2 = new Date(); // 今天
    day2.setTime(day2.getTime());
    this.today =
      day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate();
    var day3 = new Date(); // 明天
    day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
    this.tomorrow =
      day3.getFullYear() + "-" + (day3.getMonth() + 1) + "-" + day3.getDate();
  },
  methods: {
    open() {
      this.dialogFormVisible = true;
      this.form={};
      this.timeLong='';
    },
    //确认保存
    onSubmit() {
      this.addTimeOutOrder(); // 调用保存数据函数
      this.form = {};
      this.dialogFormVisible = false;
    },
    // 新增添加功能函数
    addTimeOutOrder() {
      if (this.form.channel && this.form.startTime && this.form.endTime) {
        this.$axios({
          url: "/otaHotel/order-timeout-config/addOrderTimeoutConfig",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            typeName: this.form.channel,
            startTime: this.form.startTime,
            endTime: this.form.endTime,
            timeLength: this.timeLong,
          },
        })
          .then((res) => {
          //  console.log(res, "保存成功的响应数据");
            if (res.data.data == "新增成功" && res.data.code == 0) {
              this.$message({
                message: "恭喜您已成功添加数据!",
                type: "success",
              });
              this.getOutTimeOrderList(); // 重绘
            } else {
              this.$message({
                message: res.data.data,
                type: "info",
              });
            }
          })
          .catch((error) => {
            console.log(error, "保存失败的响应数据");
          });
      }
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
/deep/.el-range-input {
  background: #f1f1f1;
  height: 40px;
}
/deep/.el-input__inner {
  width: 260px;
  height: 40px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>