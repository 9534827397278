<template>
  <div class="reviseParam">
    <el-dialog
      title="修改"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="15rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="keyTYpe" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.keyType"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="值" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="val"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getAdminConfigList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        keyType: "",
        val: "",
      },
     
        val: "",
      formLabelWidth: "80px",
      id: "", // 修改数据所需id
    };
  },
  methods: {
    open(row) {
    //  console.log(row, "修改当前行的数据");
      this.id = row.id; // id重新赋值
      this.form.name = row.name;
      this.form.keyType = row.keyType;
      this.form.val = row.val;
      this.val = row.val;
      this.dialogFormVisible = true;
    },
    //确认保存
    onSubmit() {
      this.modifyData();  // 调用修改数据功能函数
      this.dialogFormVisible = false;
      this.form={};
    },
    // 设置列表数据修改功能函数
    modifyData() {
      this.$axios({
        url: "/otaHotel/admin-config/updateAdminConfig",
        method: "post",
        header: {
          "Content-Type": "application/json",
        },
        data: {
          id: this.id,
          name: this.form.name,
          keyType: this.form.keyType,
          val: this.val,
        },
      })
        .then((res) => {
        //  console.log(res, "修改数据成功的小杨数据");
          if (res.data.code == 0 && res.data.data == "修改成功") {
            this.$message({
              type: "success",
              message: "恭喜您成功修改数据!",
            });
            this.getAdminConfigList(); //重绘视图
          } else {
            this.$message({
              type: "info",
              message: res.data.data,
            });
          }
        })
        .catch((error) => {
          cosnole.log(error, "修改数据失败的小杨数据");
        });
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>